/* Component Dependencies */
var paysBookDirectTemplate = require('templates/paysBookDirect.hbs');
var paysBookDirectTemplateConfirmation = require('templates/paysBookDirect-confirmation.hbs');
var AriesComponent = require('libs/aries-component');
var PubSub = require('libs/pub-sub');

PubSub.register(AriesComponent.extend({
  type: 'paysBookDirect',
  template: {
    'paysBookDirect': paysBookDirectTemplate,
    'paysBookDirect-confirmation': paysBookDirectTemplateConfirmation
  },
  subscribe: {
    'TOGGLE_PAYS_BOOK_DIRECT': 'togglePaysBookDirect'
  },
  togglePaysBookDirect : function _togglePaysBookDirect(){
    var _self=this;
    _self.$el.hasClass('l-display-none')?_self.$el.removeClass('l-display-none').addClass('l-display-block'):_self.$el.removeClass('l-display-block').addClass('l-display-none');
  }
}));
